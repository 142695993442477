import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APPLICATION_ROUTES, USER_ROLE_ID } from '@core/enums';
import { AuthGuard, LazyLoadGuard, LoginGuard, DeviceAuthGuard } from '@core/guards';
import { DashboardComponent } from '@layout/components';

const routes: Routes = [
	{
		path: APPLICATION_ROUTES.AUTH,
		loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
		canActivate: [LoginGuard],
	},
	{
		path: APPLICATION_ROUTES.DASHBOARD,
		component: DashboardComponent,
		canActivateChild: [AuthGuard],
		children: [
			{
				path: APPLICATION_ROUTES.USERS,
				loadChildren: () =>
					import('./modules/user-management/user-management.module').then((m) => m.UserManagementModule),
				data: { role: [USER_ROLE_ID.ADMIN] },
				canLoad: [LazyLoadGuard],
			},
			{
				path: APPLICATION_ROUTES.DEVICES,
				loadChildren: () => import('./modules/devices/devices.module').then((m) => m.DevicesModule),
				data: { role: [USER_ROLE_ID.ADMIN] },
				canLoad: [LazyLoadGuard],
			},
			{
				path: APPLICATION_ROUTES.DEVICE_STATISTICS,
				loadChildren: () =>
					import('./modules/device-statistics/device-statistics.module').then((m) => m.DeviceStatisticsModule),
				data: { role: [USER_ROLE_ID.ADMIN] },
				canLoad: [LazyLoadGuard],
			},
			{
				path: APPLICATION_ROUTES.DEVICE_GROUPS,
				loadChildren: () => import('./modules/device-groups/device-groups.module').then((m) => m.DeviceGroupsModule),
				data: { role: [USER_ROLE_ID.ADMIN, USER_ROLE_ID.MARKETAR] },
				canLoad: [LazyLoadGuard],
			},
			{
				path: APPLICATION_ROUTES.ADVERTISEMENTS,
				loadChildren: () =>
					import('./modules/advertisements/advertisements.module').then((m) => m.AdvertisementsModule),
				data: { role: [USER_ROLE_ID.ADMIN, USER_ROLE_ID.MARKETAR] },
				canLoad: [LazyLoadGuard],
			},
			{
				path: APPLICATION_ROUTES.ADVERTISEMENTS_STATISTICS,
				loadChildren: () =>
					import('./modules/advertisements-statistics/advertisements-statistics.module').then(
						(m) => m.AdvertisementsStatisticsModule
					),
				data: { role: [USER_ROLE_ID.ADMIN, USER_ROLE_ID.MARKETAR] },
				canLoad: [LazyLoadGuard],
			},
		],
	},
	{
		path: APPLICATION_ROUTES.UI,
		loadChildren: () => import('./modules/device-ui/device-ui.module').then((m) => m.DeviceUiModule),
		data: { role: [USER_ROLE_ID.DEVICE] },
		canActivate: [DeviceAuthGuard],
	},
	{
		path: '',
		redirectTo: `/${APPLICATION_ROUTES.AUTH}/${APPLICATION_ROUTES.DEVICE}`,
		pathMatch: 'full',
	},
	// {
	// 	path: APPLICATION_ROUTES.PAGE_NOT_FOUND,
	// 	component: PageNotFoundComponent,
	// },
	{
		path: '**',
		redirectTo: `/${APPLICATION_ROUTES.AUTH}/${APPLICATION_ROUTES.DEVICE}`,
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}

export enum ACTIONS {
	ADD = 'add',
	EDIT = 'edit',
	BLOCK = 'block',
	UNBLOCK = 'unblock',
	DELETE = 'delete',
	TRANSFER = 'transfer-obligations',
	ASSIGN_USER = 'assign-user',
	DEVICE_SETTINGS = 'device-settings',
	CHANGE_QUANTITY = 'change-quantity',
	PLACE_ORDER = 'place-order',
	EXPORT_PDF = 'export-pdf',
	TRANSFER_MONEY = 'transfer-money',
	CHANGE_TAPE = 'change-tape',
	DEVICE_LIST = 'device-list',
	SERVISERS_LIST = 'servisers-list',
	NEW_CAMPAIGN = 'new-campaign',
	GROUP_OF_DEVICES = 'group-of-devices',
	SINGLE_CAMPAIGN = 'single-campaign',
	CAMPAIGN_DETAILS = 'campaign-details',
	RESET_ACTIVE_MONEY = 'reset-active-money',
	DEVICE_STATS_INFO = 'device-stats-info',
	DEVICE_REFRESH = 'device-refresh',
	DEVICE_LOGOUT = 'device-logout',
}

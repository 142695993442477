/* eslint-disable no-case-declarations */
import { Injectable } from '@angular/core';
import { QueryStringParameter } from '@core/interfaces';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root',
})
export class ApiUrlBuilderService {
	private ENV_API_URL = environment.apiUrl;

	constructor() {}

	/**
	 * Generate base url
	 *
	 * @private
	 * @param {string} url => url that we need to be concated with environment API url
	 * @return {*}  {string} => https://ENV_API_URL/url/
	 * @memberof ApiUrlBuilderService
	 */
	private createUrl(url: string): string {
		if (url.includes('http') || url.includes('https')) {
			return url;
		}
		return `${this.ENV_API_URL}/${url}`;
	}

	/**
	 * Generate url with path variables
	 *
	 * @private
	 * @param {string} url => base url
	 * @param {((string | number)[])} [pathVariables=[]] => path variables that will be concated with base url
	 * @return {*}  {string} => https://ENV_API_URL/url/path1/path2.../pathN
	 * @memberof ApiUrlBuilderService
	 */
	private createUrlWithPathVariables(url: string, pathVariables: (string | number)[] = []): string {
		let decodedPathVariablesUrl = '';
		for (const path of pathVariables) {
			if (path !== undefined && path !== null) {
				decodedPathVariablesUrl += `/${decodeURIComponent(path.toString().trim())}`;
			}
		}

		return `${this.createUrl(url)}${decodedPathVariablesUrl}`.toString();
	}

	/**
	 * Generate url with query parameters
	 *
	 * @private
	 * @param {string} url => base url
	 * @param {QueryStringParameter[]} queryParameters => query parameters object
	 * @return {*}  {string} => https://ENV_API_URL/url?firstQuery=firstValue&secondQuery=secondValue...&nQuery=nValue
	 * @memberof ApiUrlBuilderService
	 */
	private createUrlWithQueryParameters(url: string, queryParameters: QueryStringParameter | undefined): string {
		const urlQueryParametersObject = new URLSearchParams(new URL(url).search);

		if (queryParameters) {
			for (const [key, value] of Object.entries(queryParameters)) {
				if (value) {
					switch (key) {
						case 'money_status':
							let storeValue = value.toString();
							storeValue = storeValue.replace('[', '').trim();
							storeValue = storeValue.replace(']', '').trim();

							const arrayForMoneyStatus = storeValue.replace(' ', '').split(',');
							urlQueryParametersObject.set("money_status['min']", arrayForMoneyStatus[0]);
							urlQueryParametersObject.set("money_status['max']", arrayForMoneyStatus[1]);
							break;

						case 'custom_devices':
							if (typeof value === 'string') {
								const valueAsArray = value
									.split('[')[1]
									.split(']')[0]
									.split(',')
									.filter((val) => val !== '');

								valueAsArray.forEach((value) => {
									urlQueryParametersObject.append('devices[]', value);
								});
							}

							break;

						default:
							urlQueryParametersObject.set(key.trim(), value.toString().trim());
							break;
					}
				}
			}

			return `${this.createUrl(url)}?${urlQueryParametersObject.toString()}`;
		}

		return this.createUrl(url);
	}

	/**
	 * Generare url for API request per provided arguments
	 *
	 * @param {string} url => base url for API request
	 * @param {((string | number)[])} [pathVariables] => path variables for API request
	 * @param {QueryStringParameter} [queryParameters] => query parameters for API request
	 * @return {*}  {string} => full API url for request
	 * @memberof ApiUrlBuilderService
	 */
	public useUrlBuilder(
		url: string,
		pathVariables?: (string | number)[],
		queryParameters?: QueryStringParameter
	): string {
		let apiUrl = this.createUrl(url);

		if (pathVariables) {
			apiUrl = this.createUrlWithPathVariables(apiUrl, pathVariables);
		}

		if (queryParameters) {
			apiUrl = this.createUrlWithQueryParameters(apiUrl, queryParameters);
		}

		return apiUrl;
	}
}

import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { QueryStringParameter } from '@core/interfaces';
import { take } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FileService {
	constructor(private readonly apiRequestService: ApiRequestService) {}

	public getAndDownloadFile(
		url: string,
		fileName: string,
		fileType: string,
		path?: string[],
		queryParameters?: QueryStringParameter
	): void {
		this.apiRequestService
			.getFileRequest(url, path, queryParameters)
			.pipe(take(1))
			.subscribe({
				next: (value: Blob) => {
					const blob = new Blob([value], { type: 'application/octet-stream' });
					const fileExtension = fileType === 'excel' ? 'xlsx' : 'pdf';

					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);

					link.download = `${fileName}.${fileExtension}`;

					link.click();

					window.URL.revokeObjectURL(link.href);
					link.remove();
				},
			});
	}
}

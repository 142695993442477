import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TOAST_TYPE } from '@core/enums';
import { ToastService } from '@core/services';
import { BehaviorSubject, Subject, delay, filter, map, takeUntil } from 'rxjs';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnInit, OnDestroy {
	@Input() message: string;
	@Input() type: TOAST_TYPE;
	@Input() duration: number; // default is 3s

	private isToastDestroyed$ = new BehaviorSubject(false);
	private destroy$ = new Subject();

	constructor(private readonly toastService: ToastService) {}

	ngOnInit(): void {
		this.isToastDestroyed$
			.pipe(
				filter((value) => !value),
				delay(this.duration),
				map(() => this.closeToast()),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.duration = 0;
		this.destroy$.next(false);
		this.destroy$.complete();
		this.isToastDestroyed$.complete();
	}

	public closeToast(): void {
		this.isToastDestroyed$.next(true);
		this.toastService.closeToast();
	}
}

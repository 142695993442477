import { APP_INITIALIZER, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from '@core/core.module';
import { LayoutModule } from '@layout/layout.module';

import { TranslationService } from '@core/services';
// import { NgxEchartsModule } from 'ngx-echarts';

export function initializeTranslationService(translationService: TranslationService) {
	return () => translationService.loadTranslations();
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		CoreModule,
		LayoutModule,
		HttpClientModule,
		// NgxEchartsModule.forRoot({
		// 	echarts: () => import('echarts'),
		// }),
	],
	providers: [
		TranslationService,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeTranslationService,
			deps: [TranslationService],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
	schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '@core/services';

@Injectable({
	providedIn: 'root',
})
export class LoginGuard implements CanActivate {
	constructor(private readonly authService: AuthService) {}

	canActivate(): boolean {
		if (this.authService.isUserLoggedIn) {
			this.authService.redirectUserAfterLogin();
			return false;
		}

		return true;
	}
}

enum PRODUCT_CODE_ENUM {
	BH_TELEKOM_DOPUNA = 'bhtDopuna',
	BIN_BIN = 'BinBin',
	MTEL_DOPUNA = 'mtelDopuna',
	MTEL_DOPUNA_FINANC = 'mtelDopunaFinanc',
	FORTNITE = 'Fortnite',
	HALOO_DOPUNA = 'halooDopuna',
	ERONET_DOPUNA = 'eronetDopuna',
	LOL = 'Lol',
	M_BON = 'mBon',
	MINECRAFT = 'Minecraft',
	NEXTBIKE = 'Nextbike',
	NINTENDO = 'Nintendo',
	PLAYSTATION = 'Playstation',
	PUBG = 'PUBG',
	S_BON = 'sBon',
	STEAM = 'Steam',
	X_BOX = 'XBox',
	E_TAG = 'ETag',
	M_BET = 'MBet',
	X_LIVE = 'XLive',
	OLX = 'Olx',
	SOCCER_BET = 'Soccer',
}

enum PRODUCT_TRANSACTION_SUCCESS_ENUM {
	NUMBER = 'Račun je uspješno dopunjen, molimo vas preuzmite oštampanu potvrdu',
	BON = 'Kupovina uspješna! Preuzmite štampani primjerak potvrde na kojem se nalazi kupljeni kôd',
}

enum PRODUCT_TRANSACTION_ERROR_ENUM {
	NUMBER = 'Desila se greška, molimo vas provjerite da li ste unijeli ispravan broj telefona i pokušajte ponovo',
	BON = 'Neuspješna kupovina, molimo vas sačekajte par minuta pa probajte ponovo',
}

export { PRODUCT_CODE_ENUM, PRODUCT_TRANSACTION_SUCCESS_ENUM, PRODUCT_TRANSACTION_ERROR_ENUM };

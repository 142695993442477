/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	ApplicationRef,
	ComponentFactoryResolver,
	ComponentRef,
	EmbeddedViewRef,
	Injectable,
	Injector,
} from '@angular/core';
import { TOAST_TYPE } from '@core/enums';
import { ToastComponent } from '@layout/components';

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	private toastComponentRef: ComponentRef<ToastComponent> | null;

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector
	) {}

	public showToast(message: string, type: TOAST_TYPE, duration = 3000): void {
		if (!this.toastComponentRef) {
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ToastComponent);
			const injector = Injector.create({ providers: [], parent: this.injector });
			const componentRef = componentFactory.create(injector);
			componentRef.instance.message = message;
			componentRef.instance.type = type;
			componentRef.instance.duration = duration;
			this.toastComponentRef = componentRef;
			this.appRef.attachView(componentRef.hostView);
			const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

			document.body.appendChild(domElem);
		}
	}

	public closeToast(): void {
		if (this.toastComponentRef) {
			this.appRef.detachView(this.toastComponentRef.hostView);
			this.toastComponentRef.destroy();
			this.toastComponentRef = null;
		}
	}
}

import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { Action, ApiResponse, CreateUser, QueryStringParameter, Role, User } from '@core/interfaces';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { ACTIONS, API_ROUTES } from '@core/enums';
import { HelperService } from './helper.service';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	constructor(
		private readonly apiRequestService: ApiRequestService,
		private readonly helperService: HelperService,
		private readonly authService: AuthService
	) {}

	private roles$: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>([]);

	private actions(user: User, hide: boolean): Action[] {
		return [
			{
				id: ACTIONS.EDIT,
				text: 'Uredi',
				icon: 'assets/images/edit-icon.svg',
				url: '',
				message: '',
				hide: false,
			},
			{
				id: user.is_blocked ? ACTIONS.UNBLOCK : ACTIONS.BLOCK,
				text: user.is_blocked ? 'Deblokiraj' : 'Blokiraj',
				icon: 'assets/images/block-icon.svg',
				url: `${API_ROUTES.USERS}/${API_ROUTES.BLOCK_AND_UNBLOCK}/${user.id}`,
				message: user.is_blocked ? 'Korisnik uspješno deblokiran' : 'Korisnik uspješno blokiran',
				hide,
			},
		].filter((action) => !action.hide);
	}

	public users$: BehaviorSubject<boolean | string> = new BehaviorSubject<boolean | string>(true);

	public dispatchActionForUsers(type: boolean | string = true): void {
		this.users$.next(type);
	}

	public get listOfRoles$(): Observable<Role[]> {
		return this.roles$.asObservable();
	}

	public updateListOfRoles(roles: Role[]): void {
		this.roles$.next(roles);
	}

	public setRoles(): void {
		this.apiRequestService
			.getRequest(API_ROUTES.ROLES)
			.pipe(
				map((value) => {
					const roles = value.data as Role[];
					this.updateListOfRoles(roles);
				}),
				take(1)
			)
			.subscribe();
	}

	public createUser(user: CreateUser): Observable<{ message: string }> {
		return this.apiRequestService.postRequest(API_ROUTES.USERS, JSON.stringify(user));
	}

	public editUser(user: CreateUser, id: number): Observable<{ message: string }> {
		return this.apiRequestService.postRequest(API_ROUTES.USERS, JSON.stringify(user), [id]);
	}

	public actionOnUser(user: CreateUser, id?: number): Observable<{ message: string }> {
		if (id) {
			return this.editUser(user, id);
		} else {
			return this.createUser(user);
		}
	}

	public getUsersList(queryParameters: QueryStringParameter): Observable<ApiResponse<User[]>> {
		return this.apiRequestService
			.getRequest(API_ROUTES.USERS, undefined, this.helperService.getPreparedQueryParameters(queryParameters))
			.pipe(
				map((res: ApiResponse<User[]>) => {
					const loggedUser = this.authService.getLoggedUser?.data.id;
					const mappedUsers = res.data.map((user: User) => {
						return {
							...user,
							actions: this.actions(user, loggedUser === user.id),
							roleName: user.role.name,
						};
					});

					res.data = mappedUsers;
					res.meta.arrayForPagination = this.helperService.generateTotalItems(res.meta.total);

					return res;
				})
			);
	}
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import {
	Action,
	ApiResponse,
	Campaign,
	CreateDevice,
	Device,
	DeviceStatistics,
	NumberOfDisplays,
	Payments,
	QueryStringParameter,
	Trends,
} from '@core/interfaces';
import { ACTIONS, API_ROUTES } from '@core/enums';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HelperService } from './helper.service';
import { Header } from '@shared/interfaces';

@Injectable({
	providedIn: 'root',
})
export class DeviceService {
	public headers: Header[] = [
		{
			key: 'name',
			text: 'Naziv uređaja',
		},
		{
			key: 'username',
			text: 'Korisničko ime',
		},
		{
			key: 'address',
			text: 'Adresa',
		},
		{
			key: 'city',
			text: 'Grad',
		},
		{
			key: 'phone_number',
			text: 'Broj telefona',
		},
		{
			key: 'finance',
			text: 'Koristi Financ',
		},
		{
			key: 'is_active',
			text: 'Dostupnost uređaja',
		},
		{
			key: 'active_money_status_fe',
			text: 'Aktivno novca',
		},
		{
			key: 'latest_paper_change',
			text: 'Zadnja izmjena trake',
		},
		{
			key: 'paper_status_percentage_fe',
			text: 'Stanje trake',
		},
		{
			key: 'last_money_pull',
			text: 'Zadnje preuzimanje novca',
		},
		{
			key: 'money_status_fe',
			text: 'Stanje novca u uređaju',
		},
		{
			key: 'usersAsServisers',
			text: 'Serviseri',
		},
		{
			key: 'refreshDevice',
			text: 'Osvježi uređaj',
		},
		{
			key: 'logoutDevice',
			text: 'Izloguj uređaj',
		},
		{
			key: 'actions',
			text: '',
		},
	];

	public headersMoneyAndPaper: Header[] = [
		{
			key: 'created_at',
			text: 'Datum i vrijeme',
		},
		{
			key: 'device_name',
			text: 'Naziv uređaja',
		},
		{
			key: 'device_username',
			text: 'Korisničko ime uređaja',
		},
		{
			key: 'address',
			text: 'Adresa',
		},
		{
			key: 'city',
			text: 'Grad',
		},
		{
			key: 'event_type',
			text: 'Tip događaja',
		},
		{
			key: 'amount',
			text: 'Iznos',
		},
	];

	public paymentsHeader: Header[] = [
		{
			key: 'created_at',
			text: 'Datum i vrijeme',
		},
		{
			key: 'device_name',
			text: 'Naziv uređaja',
		},
		{
			key: 'device_username',
			text: 'Korisničko ime',
		},
		{
			key: 'address',
			text: 'Adresa',
		},
		{
			key: 'city',
			text: 'Grad',
		},
		{
			key: 'transaction_type',
			text: 'Tip transakcije',
		},
		{
			key: 'product_name',
			text: 'Kupljeni proizvod',
		},
		{
			key: 'product_data',
			text: 'Broj telefona/kod bona',
		},
		{
			key: 'amount_in_bam',
			text: 'Iznos',
		},
		{
			key: 'payments_details',
			text: 'Detalji',
		},
	];

	constructor(
		private readonly apiRequestService: ApiRequestService,
		private readonly helperService: HelperService
	) {}

	private actions(device: Device): Action[] {
		return [
			{
				id: ACTIONS.EDIT,
				text: 'Uredi',
				icon: 'assets/images/edit-icon.svg',
				url: '',
				message: '',
			},
			{
				id: device.is_blocked ? ACTIONS.UNBLOCK : ACTIONS.BLOCK,
				text: device.is_blocked ? 'Deblokiraj' : 'Blokiraj',
				icon: 'assets/images/block-icon.svg',
				url: `${API_ROUTES.DEVICES}/${API_ROUTES.BLOCK_AND_UNBLOCK}/${device.id}`,
				message: device.is_blocked ? 'Uređaj uspješno deblokiran' : 'Uređaj uspješno blokiran',
			},
		];
	}

	private deviceStatisticsInfoAction(): Action[] {
		return [
			{
				id: ACTIONS.DEVICE_STATS_INFO,
				text: 'Info',
				icon: 'assets/images/info.png',
				url: '',
				message: '',
			},
		];
	}

	private paperStatus(paperStatus: number): string {
		return paperStatus ? `${paperStatus}%` : '0%';
	}

	private generateProductData(productLog: string | null): string {
		if (productLog === null) return '/';

		const parseLog = JSON.parse(productLog);

		if ('number' in parseLog) {
			return parseLog.number;
		}

		return parseLog.vaucher;
	}

	private generateProductLogData(productLog: string | null): string {
		if (productLog === null) return '/';

		const productLogAsString = productLog;
		const parsedProductLog = JSON.parse(productLogAsString);
		if (!parsedProductLog.amount.includes('KM')) {
			parsedProductLog.amount = `${Number(parsedProductLog.amount) / 100}KM`;
		}

		return JSON.stringify(parsedProductLog);
	}

	private isAmountInBam(amount: string): boolean {
		const formatAmount = +amount.split('.')[0];

		return formatAmount !== 0 ? true : false;
	}

	public devices$: BehaviorSubject<boolean | string> = new BehaviorSubject<boolean | string>(true);

	public deviceStatistics$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	public payments$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	public dispatchActionForDevices(type: boolean | string = true): void {
		this.devices$.next(type);
	}

	public dispatchActionForDeviceStatistcs(): void {
		this.deviceStatistics$.next(true);
	}

	public dispatchActionForPayments(): void {
		this.payments$.next(true);
	}

	public createDevice(device: CreateDevice): Observable<{ message: string }> {
		return this.apiRequestService.postRequest(API_ROUTES.DEVICES, JSON.stringify(device));
	}

	public editDevice(device: CreateDevice, id: number): Observable<{ message: string }> {
		return this.apiRequestService.postRequest(API_ROUTES.DEVICES, JSON.stringify(device), [id]);
	}

	public actionOnDevice(device: CreateDevice, id?: number): Observable<{ message: string }> {
		if (id) {
			return this.editDevice(device, id);
		} else {
			return this.createDevice(device);
		}
	}

	public initDeviceStatus(): Observable<Observable<any>> {
		return this.apiRequestService.patchRequest(API_ROUTES.DEVICE_APIS, {}, [API_ROUTES.ACTIVE]);
	}

	public getDevicesList(queryParameters: QueryStringParameter): Observable<ApiResponse<Device[]>> {
		return this.apiRequestService.getRequest(API_ROUTES.DEVICES, undefined, queryParameters).pipe(
			map((res: ApiResponse<Device[]>) => {
				const mappedDevices = res.data.map((device: Device) => {
					return {
						...device,
						active_money_status_fe: device.active_money_status
							? `${Math.floor(+device.active_money_status / 100)} KM`
							: '0 KM',
						latest_paper_change: this.helperService.setDate(device.latest_paper_change),
						last_money_pull: this.helperService.setDate(device.last_money_pull),
						paper_status_percentage_fe: this.paperStatus(device.paper_status_percentage),
						money_status: device.money_status ? device.money_status : 0,
						money_status_fe: device.money_status ? `${device.money_status} KM` : `${0} KM`,
						usersAsServisers: device.users,
						moneyColor: device.money_status < 1000 ? '#10b981' : '#ef4444',
						paperColor: +device.paper_status_percentage > 15 ? '#10b981' : '#ef4444',
						finance: device.is_financ ? 'Da' : 'Ne',
						actions: this.actions(device),
					};
				});

				res.data = mappedDevices;
				res.meta.arrayForPagination = this.helperService.generateTotalItems(res.meta.total);

				return res;
			})
		);
	}

	public deviceById(deviceId: number): Observable<Device> {
		const loggedServiserToken = sessionStorage.getItem('serviser-token') || '';
		return this.apiRequestService.getRequest(API_ROUTES.DEVICES, [deviceId], undefined, loggedServiserToken).pipe(
			map((device) => {
				return {
					...device.data,
					active_money_status_fe: device.data.active_money_status
						? `${Math.floor(+device.data.active_money_status / 100)} KM`
						: '0 KM',
					latest_paper_change_service: this.helperService.setDate(device.data.latest_paper_change),
					last_money_pull: this.helperService.setDate(device.data.last_money_pull),
					last_money_pull_serviser: this.helperService.setDate(device.data.last_money_pull),
					paper_status_percentage_fe: this.paperStatus(device.data.paper_status_percentage),
					money_status: device.data.money_status ? device.data.money_status : 0,
					money_status_fe: device.money_status ? `${device.money_status} KM` : `${0} KM`,
					moneyColor: device.data.money_status < 1000 ? '#10b981' : '#ef4444',
					paperColor: +device.data.paper_status_percentage > 15 ? '#10b981' : '#ef4444',
				};
			})
		);
	}

	public campaignsForDevice(): Observable<Campaign[]> {
		return this.apiRequestService.getRequest(API_ROUTES.DEVICE_APIS, [API_ROUTES.ADS]);
	}

	public updateNumberOfDisplaysForCampaigns(data: {
		ad_item_ids: NumberOfDisplays[];
	}): Observable<{ message: string }> {
		return this.apiRequestService.postRequest(API_ROUTES.DEVICE_APIS, data, [API_ROUTES.ADS, API_ROUTES.LOGS]);
	}

	public moneyAndPaperStatistics(queryParameters: QueryStringParameter): Observable<ApiResponse<DeviceStatistics[]>> {
		return this.apiRequestService.getRequest(API_ROUTES.STATISTICS, [API_ROUTES.MONEY_PAPER], queryParameters).pipe(
			map((res: ApiResponse<DeviceStatistics[]>) => {
				const mappedDeviceStatistics = res.data.map((deviceStatistics: DeviceStatistics) => {
					return {
						...deviceStatistics,
						event_type: deviceStatistics.amount !== null ? 'Preuzimanje novca' : 'Zamjena trake',
						created_at: this.helperService.setDate(deviceStatistics.created_at),
						amount: deviceStatistics.amount !== null ? `${deviceStatistics.amount} KM` : '/',
					};
				});

				res.data = mappedDeviceStatistics;

				res.arrayForPagination = this.helperService.generateTotalItems(res.total);

				return res;
			})
		);
	}

	public getPaymentsData(queryParameters: QueryStringParameter): Observable<ApiResponse<Payments[]>> {
		return this.apiRequestService.getRequest(API_ROUTES.STATISTICS, [API_ROUTES.PAYMENTS], queryParameters).pipe(
			map((res) => {
				const mappedPayments = res.data.data.map((payment: Payments) => {
					return {
						...payment,
						created_at: this.helperService.setDate(payment.created_at),
						transaction_type: payment.product_id !== null ? 'Kupovina' : 'Uplata novca',
						product_data: this.generateProductData(payment.product_log),
						product_name: payment.product_name !== null ? payment.product_name : '/',
						amount_in_bam: this.isAmountInBam(payment.amount_in_bam)
							? `${+payment.amount_in_bam} KM`
							: `${payment.amount} KM`,
						payments_details: this.deviceStatisticsInfoAction(),
						product_log: this.generateProductLogData(payment.product_log),
					};
				});

				res.arrayForPagination = this.helperService.generateTotalItems(res.data.total);
				res.data = mappedPayments;

				return res;
			})
		);
	}

	public getTrendsData(queryParameters: QueryStringParameter): Observable<Trends[]> {
		return this.apiRequestService.getRequest(API_ROUTES.STATISTICS, [API_ROUTES.TREND], queryParameters);
	}
}

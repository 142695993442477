/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FormService {
	// private formGroupSubmitted$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private formGroupSubmitted$ = new BehaviorSubject(false);

	public updateFormGroupSubmittedState(state: boolean): void {
		this.formGroupSubmitted$.next(state);
	}

	public get isFormGroupSubmitted(): Observable<boolean> {
		return this.formGroupSubmitted$.asObservable();
	}

	constructor() {}

	/**
	 * Check is form group valid or not
	 *
	 * @param {FormGroup} formGroup => form group for which we need to check status
	 * @return {*}  {boolean}
	 * @memberof FormService
	 */
	public isFormGroupValid(formGroup: FormGroup): boolean {
		return formGroup.valid;
	}

	/**
	 * Get form group controls
	 *
	 * @param {FormGroup} formGroup => form group for which we need controls
	 * @param {string} formControl => form control for which we need full data
	 * @return {*}  {{ [key: string]: AbstractControl }}
	 * @memberof FormService
	 */
	public formGroupControls(formGroup: FormGroup, formControl: string): AbstractControl {
		return formGroup.controls[formControl];
	}

	/**
	 * Check when to show error in UI for fields inside form
	 *
	 * @param {boolean} isFormSubmitted => can be true or false (depends is user submit form or not)
	 * @param {FormGroup} formGroup => form group for which we need controls
	 * @param {string} formControl => form control for which we checking errors
	 * @return {*}  {(false | null | ValidationErrors)}
	 * @memberof FormService
	 */
	public showError(
		isFormSubmitted: boolean,
		formGroup: FormGroup,
		formControl: string
	): false | null | ValidationErrors {
		return isFormSubmitted && this.formGroupControls(formGroup, formControl).errors;
	}

	/**
	 * Update any form control value
	 *
	 * @param form => form from component
	 * @param formControl => unique form control
	 * @param data => new data
	 */
	public updateFormControl(form: FormGroup, formControl: string, data: any): void {
		this.formGroupControls(form, formControl).setValue(data);
	}
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class TranslationService {
	private currentLanguage: string = 'en'; // Default language
	private languageSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.currentLanguage);
	private translations: { [key: string]: { [lang: string]: string } } = { en: {}, bih: {} };
	private folderPath: string = 'assets/translations/';

	constructor(private http: HttpClient) {}

	async loadTranslations(): Promise<void> {
		try {
			const enTranslations: any = await this.http
				.get<{ [key: string]: string }>(`${this.folderPath}en.json`)
				.toPromise();
			const bihTranslations: any = await this.http
				.get<{ [key: string]: string }>(`${this.folderPath}bih.json`)
				.toPromise();

			this.translations = {
				en: enTranslations,
				bih: bihTranslations,
				// Add more languages as needed
			};
		} catch (error) {
			console.error('Error loading translations:', error);
		}
	}

	translate(key: string, lang?: string): string {
		lang = lang || this.currentLanguage;
		if (this.translations[key] && this.translations[key][lang]) {
			return this.translations[key][lang];
		} else {
			// Return a placeholder or the key itself if translation is not found
			return key;
		}
	}

	getCurrentLanguage(): string {
		return this.currentLanguage;
	}

	getCurrentLanguageObservable(): Observable<string> {
		return this.languageSubject.asObservable();
	}

	changeLanguage(newLanguage: string): void {
		if (Object.values(this.translations).some((translation) => translation[newLanguage])) {
			this.currentLanguage = newLanguage;
			this.languageSubject.next(newLanguage);
		} else {
			console.error(`Language '${newLanguage}' is not supported.`);
		}
	}
}

import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { APPLICATION_ROUTES } from '@core/enums';
import { AuthService } from '@core/services';

@Injectable({
	providedIn: 'root',
})
export class LazyLoadGuard implements CanLoad {
	constructor(
		private readonly authService: AuthService,
		private readonly router: Router
	) {}

	canLoad(): boolean {
		if (!this.authService.isUserLoggedIn) {
			this.router.navigate([APPLICATION_ROUTES.AUTH, APPLICATION_ROUTES.DEVICE]);
		}

		return true;
	}
}

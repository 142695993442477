export enum APPLICATION_ROUTES {
	AUTH = 'auth',
	LOGIN = 'login',
	DEVICE = 'device',
	FORGOT_PASSWORD = 'forgot-password',
	RESET_PASSWORD = 'reset-password',
	DASHBOARD = 'dashboard',
	USERS = 'users',
	DEVICES = 'devices',
	DEVICE_STATISTICS = 'device-statistics',
	DEVICE_GROUPS = 'device-groups',
	ADVERTISEMENTS = 'advertisements',
	ADVERTISEMENTS_STATISTICS = 'advertisements-statistics',
	UI = 'ui',
	DEVICE_SERVICE = 'device-service',
	PAGE_NOT_FOUND = 'page-not-found',
}

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptors';
import { AuthService, ToastService, UserService } from './services';
import { AuthGuard, DeviceAuthGuard, LazyLoadGuard, LoginGuard } from './guards';

@NgModule({
	declarations: [],
	imports: [CommonModule],
	providers: [
		AuthService,
		UserService,
		ToastService,
		LoginGuard,
		LazyLoadGuard,
		AuthGuard,
		DeviceAuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
		},
	],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() core: CoreModule) {
		if (core) {
			throw new Error('You should import code module only in the root module!');
		}
	}
}

import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ACTIONS, API_ROUTES } from '@core/enums';
import {
	Action,
	CampaignItem,
	ApiResponse,
	Campaign,
	CreateCampaign,
	QueryStringParameter,
	CampaignItemDetails,
	CampaignStatistic,
	Summary,
	TopAds,
	ChartCampaign,
} from '@core/interfaces';
import { HelperService } from './helper.service';
import { Header } from '@shared/interfaces';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class CampaignService {
	private TODAY = moment();

	public headers: Header[] = [
		{
			key: 'name',
			text: 'Naziv kampanje',
		},
		{
			key: 'typeText',
			text: 'Tip kampanje',
		},
		{
			key: 'group_of_devices',
			text: 'Grupe uređaja',
		},
		{
			key: 'number_of_elements',
			text: 'Broj elemenata',
		},
		{
			key: 'is_active',
			text: 'Aktivna kampanja',
		},
		{
			key: 'updated_at',
			text: 'Posljednja izmjena',
		},
		{
			key: 'start_date',
			text: 'Datum početka',
		},
		{
			key: 'end_date',
			text: 'Datum prestanka',
		},
		{
			key: 'actions',
			text: '',
		},
	];
	constructor(
		private readonly apiRequestService: ApiRequestService,
		private readonly helperService: HelperService
	) {}

	private actions(campaign: Campaign): Action[] {
		return [
			// {
			// 	id: ACTIONS.NEW_CAMPAIGN,
			// 	text: 'Uredi',
			// 	icon: 'assets/images/edit-icon.svg',
			// 	url: '',
			// 	message: '',
			// },
			{
				id: campaign.is_blocked ? ACTIONS.UNBLOCK : ACTIONS.BLOCK,
				text: campaign.is_blocked ? 'Deblokiraj' : 'Blokiraj',
				icon: 'assets/images/block-icon.svg',
				url: `${API_ROUTES.ADS}/${API_ROUTES.BLOCK_AND_UNBLOCK}/${campaign.id}`,
				message: campaign.is_blocked ? 'Kampanja je uspješno deblokirana' : 'Kampanja je uspješno blokirana',
			},
		];
	}

	private isCampaignActive(campaign: Campaign): boolean {
		if (campaign.is_blocked) {
			return false;
		}

		const startDate = moment(campaign.start_date);
		const endDate = moment(campaign.end_date);

		const isTodayInRange = this.TODAY.isBetween(startDate, endDate, null, '[]');

		return isTodayInRange;
	}

	public campaigns$: BehaviorSubject<boolean | string> = new BehaviorSubject<boolean | string>(true);

	public dispatchActionForCampaigns(type: boolean | string = true): void {
		this.campaigns$.next(type);
	}

	public createCampaign(data: CreateCampaign): Observable<{ message: string; ad: Campaign }> {
		return this.apiRequestService.postRequest(API_ROUTES.ADS, data);
	}

	public editCampaign(data: CreateCampaign, id: number): Observable<{ message: string; ad: Campaign }> {
		return this.apiRequestService.postRequest(API_ROUTES.ADS, data, [id]);
	}

	public actionOnCampaign(campaign: CreateCampaign, id?: number): Observable<{ message: string }> {
		if (id) {
			return this.editCampaign(campaign, id);
		} else {
			return this.createCampaign(campaign);
		}
	}

	public createItemForCampaign(item: CampaignItem, id: number): Observable<{ message: string }> {
		const formData = new FormData();
		formData.append('ad_name', item.ad_name);
		formData.append('duration', item.duration);
		formData.append('file', item.file, item.file.name);
		return this.apiRequestService.postRequest(API_ROUTES.ADS, formData, [id, API_ROUTES.FILE]);
	}

	public editItemFormCampaign(
		itemData: { ad_name: string; duration: string },
		campaignId: number,
		itemId?: number
	): Observable<{ message: string }> {
		return this.apiRequestService.postRequest(API_ROUTES.ADS, itemData, [
			campaignId,
			API_ROUTES.FILE,
			itemId ? itemId : '',
		]);
	}

	public getListOfItemsForCampaign(id: number): Observable<ApiResponse<CampaignItemDetails[]>> {
		return this.apiRequestService.getRequest(API_ROUTES.ADS, [id]);
	}

	public deleteItemFromCampaign(campaignId: number, campaignItemId: number): Observable<{ message: string }> {
		return this.apiRequestService.deleteRequest(API_ROUTES.ADS, [campaignId, API_ROUTES.FILE, campaignItemId]);
	}

	public getCampaignsList(queryParameters: QueryStringParameter): Observable<ApiResponse<Campaign[]>> {
		return this.apiRequestService
			.getRequest(API_ROUTES.ADS, undefined, this.helperService.getPreparedQueryParameters(queryParameters))
			.pipe(
				map((res: ApiResponse<Campaign[]>) => {
					const mappedCampaigns = res.data.map((campaign: Campaign) => {
						return {
							...campaign,
							is_active: this.isCampaignActive(campaign),
							start_date: this.helperService.setDate(campaign.start_date),
							end_date: this.helperService.setDate(campaign.end_date),
							updated_at: this.helperService.setDate(campaign.updated_at),
							typeText: this.helperService.getCampaignType(campaign.type).value,
							actions: this.actions(campaign),
						};
					});

					res.data = mappedCampaigns;
					res.meta.arrayForPagination = this.helperService.generateTotalItems(res.meta.total);

					return res;
				})
			);
	}

	public getStatisticsAsList(queryParameters: QueryStringParameter): Observable<CampaignStatistic[]> {
		return this.apiRequestService
			.getRequest(API_ROUTES.ADS, [API_ROUTES.STATISTICS, API_ROUTES.LIST], queryParameters)
			.pipe(
				map((data: CampaignStatistic[]) => {
					const mappedCampaignStatistics = data.map((stats: CampaignStatistic) => {
						return {
							...stats,
							typeText: this.helperService.getCampaignType(stats.type).value,
						};
					});

					return mappedCampaignStatistics;
				})
			);
	}

	public getStatisticsAsChart(queryParameters: QueryStringParameter): Observable<ChartCampaign[]> {
		return this.apiRequestService.getRequest(
			API_ROUTES.ADS,
			[API_ROUTES.STATISTICS, API_ROUTES.CHARTS],
			queryParameters
		);
	}

	public getSummary(queryParameters: QueryStringParameter): Observable<Summary> {
		return this.apiRequestService.getRequest(
			API_ROUTES.ADS,
			[API_ROUTES.STATISTICS, API_ROUTES.LIST, API_ROUTES.SUMMARY],
			queryParameters
		);
	}

	public getCampaignDetails(campaignId: number, queryParameters: QueryStringParameter): Observable<TopAds[]> {
		return this.apiRequestService.getRequest(
			API_ROUTES.ADS,
			[API_ROUTES.STATISTICS, API_ROUTES.LIST, campaignId],
			queryParameters
		);
	}

	public getCampaignItemDetails(
		campaignId: number,
		queryParameters: QueryStringParameter
	): Observable<{ id: number; name: string; number_of_displays: string }[]> {
		return this.apiRequestService.getRequest(
			API_ROUTES.ADS,
			[API_ROUTES.STATISTICS, API_ROUTES.LIST, API_ROUTES.BY_AD_ITEM, campaignId],
			queryParameters
		);
	}
}

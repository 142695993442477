import { Component } from '@angular/core';
import { APPLICATION_ROUTES, USER_ROLE_ID } from '@core/enums';
import { AuthService } from '@core/services';

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
	public loggedRoleId: number;
	constructor(private readonly authService: AuthService) {
		this.loggedRoleId = this.authService.userRole ? this.authService.userRole : -1;
	}

	public navigationLinks = [
		{
			id: 1,
			path: APPLICATION_ROUTES.USERS,
			text: 'Korisnici',
			role: [USER_ROLE_ID.ADMIN],
		},
		{
			id: 2,
			path: APPLICATION_ROUTES.DEVICES,
			text: 'Uređaji',
			role: [USER_ROLE_ID.ADMIN],
		},
		{
			id: 3,
			path: APPLICATION_ROUTES.DEVICE_STATISTICS,
			text: 'Statistika uređaja',
			role: [USER_ROLE_ID.ADMIN],
		},
		{
			id: 4,
			path: APPLICATION_ROUTES.DEVICE_GROUPS,
			text: 'Grupe uređaja',
			role: [USER_ROLE_ID.ADMIN, USER_ROLE_ID.MARKETAR],
		},
		{
			id: 4,
			path: APPLICATION_ROUTES.ADVERTISEMENTS,
			text: 'Reklame',
			role: [USER_ROLE_ID.ADMIN, USER_ROLE_ID.MARKETAR],
		},
		{
			id: 5,
			path: APPLICATION_ROUTES.ADVERTISEMENTS_STATISTICS,
			text: 'Statistika reklama',
			role: [USER_ROLE_ID.ADMIN, USER_ROLE_ID.MARKETAR],
		},
	];

	logout(): void {
		this.authService.logout();
	}
}

import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { LoggedInUser, LoginUser, ServiserLoginData } from '@core/interfaces';
import { Observable, concatMap, map, take } from 'rxjs';
import { API_ROUTES, APPLICATION_ROUTES, USER_ROLE_ID } from '@core/enums';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private readonly apiRequestService: ApiRequestService,
		private readonly router: Router
	) {}

	public login(loginData: LoginUser, roleId: number): Observable<LoggedInUser> {
		const apiRoute = roleId === USER_ROLE_ID.ADMIN ? API_ROUTES.SANCTUM : API_ROUTES.DEVICE_MACHINE;
		const getUserDataUrl =
			roleId === USER_ROLE_ID.ADMIN
				? API_ROUTES.TEST_PROTECTED
				: `${API_ROUTES.DEVICE_APIS}/${API_ROUTES.TEST_PROTECTED}`;
		return this.apiRequestService.postRequest(apiRoute, loginData, [API_ROUTES.TOKEN]).pipe(
			concatMap((value) => {
				this.storeToken(value.token);
				return this.apiRequestService.getRequest(getUserDataUrl);
			}),
			map((user: LoggedInUser) => {
				const mapUser = {
					...user.data,
					role: {
						id: user.data.role ? user.data.role.id : roleId,
						name: user.data.role ? user.data.role.name : 'Device',
					},
				};

				this.storeLoggedUser({ data: mapUser });
				return user;
			})
		);
	}

	public getLoggedData(): Observable<LoggedInUser> {
		return this.apiRequestService.getRequest(API_ROUTES.DEVICE_APIS, [API_ROUTES.TEST_PROTECTED]).pipe(
			map((user: LoggedInUser) => {
				const mapUser = {
					...user.data,
					role: {
						id: user.data.role ? user.data.role.id : USER_ROLE_ID.DEVICE,
						name: user.data.role ? user.data.role.name : 'Device',
					},
				};

				this.storeLoggedUser({ data: mapUser });
				return user;
			})
		);
	}

	public loginAsServiser(loginData: ServiserLoginData): Observable<LoggedInUser> {
		return this.apiRequestService.postRequest(API_ROUTES.SANCTUM, loginData, [API_ROUTES.TOKEN]).pipe(
			concatMap((value) => {
				sessionStorage.setItem('serviser-token', value.token);
				return this.apiRequestService.getRequest(API_ROUTES.TEST_PROTECTED, undefined, undefined, value.token).pipe(
					map((user: LoggedInUser) => {
						sessionStorage.setItem('serviser-user', JSON.stringify(user.data));
						return user;
					})
				);
			})
		);
	}

	public storeToken(token: string): void {
		localStorage.setItem('token', token);
	}

	public get getStoredToken(): string | null {
		return localStorage.getItem('token');
	}

	public get isUserLoggedIn(): boolean {
		const accessToken = this.getStoredToken;

		return accessToken !== null && accessToken !== '' && accessToken !== undefined;
	}

	public storeLoggedUser(user: LoggedInUser): void {
		localStorage.setItem('user', JSON.stringify(user));

		this.redirectUserAfterLogin();
	}

	public get getLoggedUser(): LoggedInUser | undefined {
		const user = localStorage.getItem('user');

		if (user !== null && user !== 'undefined' && user !== '') {
			return JSON.parse(user);
		}

		return undefined;
	}

	public get userRole(): number | undefined | null {
		return this.getLoggedUser?.data.role?.id;
	}

	public redirectUserAfterLogin(): void {
		if (this.getLoggedUser) {
			if (this.userRole === USER_ROLE_ID.ADMIN) {
				this.router.navigate([APPLICATION_ROUTES.DASHBOARD, APPLICATION_ROUTES.USERS]);
			}

			if (this.userRole === USER_ROLE_ID.MARKETAR) {
				this.router.navigate([APPLICATION_ROUTES.DASHBOARD, APPLICATION_ROUTES.DEVICE_GROUPS]);
			}

			if (this.userRole === USER_ROLE_ID.DEVICE) {
				this.router.navigate([APPLICATION_ROUTES.UI, APPLICATION_ROUTES.DEVICE]);
			}
		}
	}

	public redirectUserAfterLogout(): void {
		if (this.getLoggedUser) {
			if (this.userRole === USER_ROLE_ID.ADMIN || this.userRole === USER_ROLE_ID.MARKETAR) {
				this.router.navigate([APPLICATION_ROUTES.AUTH, APPLICATION_ROUTES.LOGIN]);
			}

			if (this.userRole === USER_ROLE_ID.DEVICE) {
				this.router.navigate([APPLICATION_ROUTES.AUTH, APPLICATION_ROUTES.DEVICE]);
			}
		} else {
			this.router.navigate([APPLICATION_ROUTES.AUTH, APPLICATION_ROUTES.DEVICE]);
		}
	}

	public logout(): void {
		this.redirectUserAfterLogout();
		localStorage.clear();
		sessionStorage.clear();
	}

	public deviceLogout(): void {
		this.apiRequestService
			.getRequest(API_ROUTES.DEVICE_APIS, [API_ROUTES.LOGOUT])
			.pipe(take(1))
			.subscribe({
				next: () => this.logout(),
			});
	}
}

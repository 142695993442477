import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DashboardComponent, NavigationComponent, ToastComponent } from './components';

@NgModule({
	declarations: [DashboardComponent, NavigationComponent, ToastComponent],
	imports: [CommonModule, RouterModule],
	schemas: [NO_ERRORS_SCHEMA],
})
export class LayoutModule {}

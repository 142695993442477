import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router } from '@angular/router';
import { APPLICATION_ROUTES } from '@core/enums';
import { AuthService } from '@core/services';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
	constructor(
		private readonly authService: AuthService,
		private readonly router: Router
	) {}

	canActivateChild(childRoute: ActivatedRouteSnapshot): boolean {
		if (this.authService.isUserLoggedIn) {
			if (childRoute.data['role'] && childRoute.data['role'].indexOf(this.authService.userRole) === -1) {
				this.authService.redirectUserAfterLogin();
				return false;
			}
			return true;
		}

		this.router.navigate([APPLICATION_ROUTES.AUTH, APPLICATION_ROUTES.DEVICE]);
		return false;
	}
}

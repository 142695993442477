/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ApiUrlBuilderService } from '@core/services';
import { QueryStringParameter } from '@core/interfaces';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ApiRequestService {
	constructor(
		private readonly apiUrlBuilder: ApiUrlBuilderService,
		private readonly http: HttpClient
	) {}

	public getRequest(
		url: string,
		pathVariables?: (string | number)[],
		queryParameters?: QueryStringParameter,
		serviserToken?: string
	): Observable<any> {
		return this.http
			.get(
				this.apiUrlBuilder.useUrlBuilder(url, pathVariables, queryParameters),
				serviserToken
					? {
							headers: { 'Serviser-Authorization': `Bearer ${serviserToken}` },
					  }
					: {}
			)
			.pipe(catchError(this.handleError));
	}

	public getFileRequest(
		url: string,
		pathVariables?: (string | number)[],
		queryParameters?: QueryStringParameter
	): Observable<any> {
		return this.http.get(this.apiUrlBuilder.useUrlBuilder(url, pathVariables, queryParameters), {
			responseType: 'blob',
		});
	}

	public postRequest(
		url: string,
		data: any,
		pathVariables?: (string | number)[],
		queryParameters?: QueryStringParameter,
		serviserToken?: string
	): Observable<any> {
		return this.http
			.post(
				this.apiUrlBuilder.useUrlBuilder(url, pathVariables, queryParameters),
				data,
				serviserToken
					? {
							headers: { 'Serviser-Authorization': `Bearer ${serviserToken}` },
					  }
					: {}
			)
			.pipe(catchError(this.handleError));
	}

	public putRequest(
		url: string,
		data: any,
		pathVariables?: (string | number)[],
		queryParameters?: QueryStringParameter
	): Observable<any> {
		return this.http
			.put(this.apiUrlBuilder.useUrlBuilder(url, pathVariables, queryParameters), data)
			.pipe(catchError(this.handleError));
	}

	public patchRequest(
		url: string,
		data: any,
		pathVariables?: (string | number)[],
		queryParameters?: QueryStringParameter
	): Observable<any> {
		return this.http
			.patch(this.apiUrlBuilder.useUrlBuilder(url, pathVariables, queryParameters), data)
			.pipe(catchError(this.handleError));
	}

	public deleteRequest(
		url: string,
		pathVariables?: (string | number)[],
		queryParameters?: QueryStringParameter,
		data?: any
	): Observable<any> {
		return this.http
			.delete(this.apiUrlBuilder.useUrlBuilder(url, pathVariables, queryParameters), data)
			.pipe(catchError(this.handleError));
	}

	/**
	 * Handle http error
	 *
	 * @private
	 * @param {HttpErrorResponse} error => error response
	 * @return {*}  {Observable<any>}
	 * @memberof ApiRequestsService
	 */
	private handleError(error: HttpErrorResponse): Observable<any> {
		return throwError(() => error);
	}
}

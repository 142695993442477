/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService, ToastService } from '@core/services';
import { TOAST_TYPE } from '@core/enums';

@Injectable()
class JwtInterceptor implements HttpInterceptor {
	public static nonBearerRoutes: RegExp[] = [/login/, /sanctum/, /device-machine/];
	public refreshingToken: boolean;

	constructor(
		private readonly authService: AuthService,
		private readonly toastService: ToastService
	) {}

	private static checkForNonBearerTokenRequests(requestUrl: string): boolean[] {
		return this.nonBearerRoutes.map((request: RegExp) => request.test(requestUrl));
	}

	private static addToken(req: HttpRequest<any>, tokenData: string): HttpRequest<any> {
		if (this.checkForNonBearerTokenRequests(req.url).includes(true)) {
			return req;
		}

		if (/file/.test(req.url)) {
			return req.clone({
				setHeaders: {
					Accept: '*/*',
					Authorization: `Bearer ${tokenData}`,
				},
			});
		}

		return req.clone({
			setHeaders: {
				Authorization: `Bearer ${tokenData}`,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		});
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		if (this.authService.getStoredToken !== null) {
			const serviserToken = request.headers.get('Serviser-Authorization');
			request = JwtInterceptor.addToken(request, serviserToken ? serviserToken : this.authService.getStoredToken);
		}

		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status !== 401 && error.status !== 403) {
					let { message } = error.error;

					if (!message) {
						message =
							'Greška sa obradom zahtjeva, molimo vas provjerite vašu internet konekciju i pokušajte ponovo kasnije';
					}

					if (message.includes('credentials') && message.includes('incorrect')) {
						message = 'Molimo provjerite korisničko ime i šifru';
					}

					if (message.includes('email') && message.includes('already')) {
						message = 'Već postoji korisnik sa ovom email adresom';
					}

					if (message.includes('User') && message.includes('blocked')) {
						message = 'Korisnik je blokiran';
					}

					if (message.includes('Too Many') && message.includes('Attempts')) {
						message =
							'Bezuspješno ste pokušali da se logujete u sistem pet puta, vaš nalog je blokiran. Kontaktirajte administratora kako bi vaš nalog bio odblokiran';
					}

					if (message.includes('MAC') && message.includes('address')) {
						message = 'Molimo vas da unesete validnu MAC adresu uređaja';
					}

					if (message.includes('mac address') && message.includes('taken')) {
						message = 'Mac adresa je već zauzeta.';
					}

					if (message.includes('because someone is already logged')) {
						message = 'Ne možete se prijaviti jer je neko već prijavljen sa istim korisničkim imenom';
					}

					if (message.includes('have mac address')) {
						message = 'Nije se moguće prijaviti kada uređaj nema mac adresu';
					}

					if (!/transactions/.test(request.url)) {
						this.toastService.showToast(message, TOAST_TYPE.DANGER, 4000);
					}
				}

				if (error.status === 404 && error.statusText === 'Not Found') {
					// this.router.navigate(["/404"]); // uncomment this when 404 page would be implemented
				}

				if ((error.status === 401 || error.status === 403) && !/login/.test(request.url)) {
					this.authService.logout();
				}
				throw error;
			})
		);
	}
}

export { JwtInterceptor };

enum API_ROUTES {
	AUTH = 'auth',
	LOGOUT = 'logout',
	SANCTUM = 'sanctum',
	DEVICE_MACHINE = 'device-machine',
	DEVICE_APIS = 'device-apis',
	TOKEN = 'token',
	TEST_PROTECTED = 'test-protected',
	USERS = 'users',
	ROLES = 'roles',
	BLOCK_AND_UNBLOCK = 'block-and-unblock',
	DEVICES = 'devices',
	DEVICE_GROUPS = 'device-groups',
	MONEY_WITHDRAW = 'money-withdraw',
	PAPER_CHANGE = 'paper-change',
	ADS = 'content',
	FILE = 'file',
	EXPORT = 'export',
	TRANSACTIONS = 'transactions',
	PRODUCTS = 'products',
	LOGS = 'logs',
	STATISTICS = 'statistics',
	CHARTS = 'charts',
	LIST = 'list',
	SUMMARY = 'summary',
	BY_AD_ITEM = 'by-ad-item',
	MONEY_PAPER = 'money-and-paper',
	PAYMENTS = 'payments',
	TREND = 'trend',
	DISMISS_ACTIVE_MONEY = 'dismiss-active-money',
	ACTIVE = 'active',
	PRINT_SLIP = 'print-slip',
	REFRESH = 'refresh',
	REMOVE_TOKEN = 'remove-token',
}

export { API_ROUTES };

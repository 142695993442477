enum USER_ROLE {
	ADMIN = 'Admin',
	MARKETAR = 'Marketar',
	SERVISER = 'Serviser',
	DEVICE = 'Device',
}

enum USER_ROLE_ID {
	ADMIN = 1,
	MARKETAR = 3,
	SERVISER = 2,
	DEVICE = 4,
}

export { USER_ROLE, USER_ROLE_ID };

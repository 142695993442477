import { Injectable } from '@angular/core';
import { QueryStringParameter } from '@core/interfaces';
import { Dropdown } from '@shared/interfaces';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class HelperService {
	public campaignTypes: Dropdown[] = [
		{
			id: 1,
			value: 'Full screen',
		},
		{
			id: 2,
			value: 'Pop up',
		},
	];

	constructor() {}

	public generateTotalItems(total: number): number[] {
		const totalItemsArray = [];

		for (let i = 0; i < total; i++) {
			totalItemsArray.push(i);
		}

		return totalItemsArray;
	}

	public getPreparedQueryParameters(queryParameters: QueryStringParameter): QueryStringParameter {
		const queryParams: QueryStringParameter = {};
		for (const [key, value] of Object.entries(queryParameters)) {
			if (value !== '') {
				queryParams[key] = value;
			}
		}

		return queryParams;
	}

	public getCampaignType(typeId: number): Dropdown {
		return this.campaignTypes.filter((campaign) => campaign.id === typeId)[0];
	}

	public setDate(date: Date | string): string {
		if (!date) {
			return '/';
		}
		return moment(date).format('DD.MM.YYYY HH:mm:ss');
	}

	public setManualDate(date: Date | string): string {
		const extractDate = date.toString().split('.')[0].split('T')[0].split('-');
		const extractTime = date.toString().split('.')[0].split('T')[1];
		return `${extractDate[2]}.${extractDate[1]}.${extractDate[0]} ${extractTime}`;
	}
}

<!-- begin: Navigation -->
<div class="navigation d-flex align-items-center justify-content-between">
	<ul class="d-flex">
		<ng-container *ngFor="let link of navigationLinks">
			<li *ngIf="link.role.includes(loggedRoleId)">
				<a routerLinkActive="active" class="d-flex" [routerLink]="link.path">{{ link.text }}</a>
			</li>
		</ng-container>
	</ul>
	<button type="button" (click)="logout()">Odjavi se</button>
</div>
<!-- end:: Navigation -->

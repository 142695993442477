import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class OnlineStatusService {
	private _onlineStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public status$: Observable<boolean> = this._onlineStatus$.asObservable();

	constructor() {
		this._onlineStatus$.next(window.navigator.onLine);

		window.addEventListener('online', () => {
			this._onlineStatus$.next(true);
		});

		window.addEventListener('offline', () => {
			this._onlineStatus$.next(false);
		});
	}
}

import { Injectable } from '@angular/core';
import { Header } from '@shared/interfaces';
import { ApiRequestService } from './api-request.service';
import { ACTIONS, API_ROUTES } from '@core/enums';
import { Action, ApiResponse, CreateDeviceGroup, DeviceGroup, QueryStringParameter } from '@core/interfaces';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({
	providedIn: 'root',
})
export class DeviceGroupsService {
	public headers: Header[] = [
		{
			key: 'deviceGroupNameFe',
			text: 'Naziv grupe',
		},
		{
			key: 'numberOfDevices',
			text: 'Broj uređaja u grupi',
		},
		{
			key: 'devices_fe',
			text: 'Uređaji',
		},
		{
			key: 'actions',
			text: '',
		},
	];

	constructor(
		private readonly apiRequestService: ApiRequestService,
		private readonly helperService: HelperService
	) {}

	private actions(deviceGroup: DeviceGroup): Action[] {
		return [
			{
				id: ACTIONS.EDIT,
				text: 'Uredi',
				icon: 'assets/images/edit-icon.svg',
				url: '',
				message: '',
			},
			{
				id: ACTIONS.DELETE,
				text: 'Obriši',
				icon: 'assets/images/trash.svg',
				url: `${API_ROUTES.DEVICE_GROUPS}/${deviceGroup.id}`,
				message: 'Grupa uređaja uspješno obrisana',
			},
		];
	}

	public deviceGroups$: BehaviorSubject<boolean | string> = new BehaviorSubject<boolean | string>(true);

	public dispatchActionForDeviceGroups(type: boolean | string = true): void {
		this.deviceGroups$.next(type);
	}

	public createDeviceGroup(deviceGroup: CreateDeviceGroup): Observable<{ message: string }> {
		return this.apiRequestService.postRequest(API_ROUTES.DEVICE_GROUPS, JSON.stringify(deviceGroup));
	}

	public editDeviceGroup(deviceGroup: CreateDeviceGroup, id: number): Observable<{ message: string }> {
		return this.apiRequestService.postRequest(API_ROUTES.DEVICE_GROUPS, JSON.stringify(deviceGroup), [id]);
	}

	public actionOnDeviceGroup(device: CreateDeviceGroup, id?: number): Observable<{ message: string }> {
		if (id) {
			return this.editDeviceGroup(device, id);
		} else {
			return this.createDeviceGroup(device);
		}
	}

	public getDeviceGroupList(queryParameters: QueryStringParameter): Observable<ApiResponse<DeviceGroup[]>> {
		return this.apiRequestService.getRequest(API_ROUTES.DEVICE_GROUPS, undefined, queryParameters).pipe(
			map((res: ApiResponse<DeviceGroup[]>) => {
				const mappedDeviceGroups = res.data.map((deviceGroup: DeviceGroup) => {
					return {
						...deviceGroup,
						deviceGroupNameFe: deviceGroup.name,
						devices_fe: deviceGroup.devices.map((device) => device.name).join(', '),
						numberOfDevices: deviceGroup.devices.length,
						actions: this.actions(deviceGroup),
					};
				});

				res.data = mappedDeviceGroups;
				res.meta.arrayForPagination = this.helperService.generateTotalItems(res.meta.total);

				return res;
			})
		);
	}
}

import { Injectable } from '@angular/core';
import { Observable, of, switchMap, timer } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SchedulerService {
	constructor() {}

	scheduleApiCall(): Observable<boolean> {
		const now = new Date();
		const targetTime = new Date(now);
		targetTime.setHours(4, 0, 0, 0);

		let delay = targetTime.getTime() - now.getTime();
		if (delay < 0) {
			delay += 24 * 60 * 60 * 1000;
		}
		return timer(delay, 24 * 60 * 60 * 1000).pipe(switchMap(() => of(true)));
	}
}

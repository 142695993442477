import { Component } from '@angular/core';
import { UserService } from '@core/services';

@Component({
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
	constructor(private readonly userService: UserService) {
		this.userService.setRoles();
	}
}
